<template>
  <TransitionRoot v-if="loadModal" appear :show="modalIsOpen" as="template">
    <Dialog as="div" @close="modalForm.closeModalWithUnregister(modalId)">
      <div class="fixed inset-0 z-50 overflow-y-auto">
        <div class="flex items-center justify-center h-full px-8 text-center">
          <DialogOverlay
            class="fixed inset-0 flex items-center justify-center bg-gray-900 opacity-50"
          />

          <span class="inline-block h-full align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="transition-opacity duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div
              class="relative inline-block w-full max-w-2xl bg-white shadow-xl transition-all transform max-h-[95vh] overflow-y-scroll overflow-x-hidden"
            >
              <div class="max-w-xl px-8 py-20 mx-auto">
                <slot></slot>
              </div>
              <button
                type="button"
                class="absolute right-0.5 z-10 flex items-center justify-center w-8 h-8 bg-white rounded-full top-1"
                @click="modalForm.closeModalWithUnregister(modalId)"
              >
                <i
                  class="block text-4xl text-bc-dark-blue icon icon-exit-circle"
                ></i>
              </button>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import { storeToRefs } from "pinia";
const modalForm = useModalForm();
const { modalIsOpen } = storeToRefs(modalForm);

const props = defineProps({
  modalId: String,
});

const loadModal = computed(() => {
  if (!props.modalId) return true;

  return modalForm.isModalActive(props.modalId) ? true : false;
});
</script>
